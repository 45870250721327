<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-text>
          <v-card outlined class="d-flex justify-space-between">
            <div>
              <template v-if="Lo_Question.status === 'Definitiv'">
                <v-chip
                  class="ma-1 mt-2"
                  color="success"
                  label
                  small
                  text-color="white"
                >
                  <v-icon small left> mdi-check </v-icon>
                  Staus: {{ Lo_Question.status }}
                </v-chip>
              </template>
              <template v-else>
                <v-chip
                  class="ma-1 mt-2"
                  color="warning"
                  label
                  small
                  text-color="white"
                >
                  Staus: {{ Lo_Question.status }}
                </v-chip>
              </template>
            </div>
          </v-card>
          <!--Nummer-->
          <div>
            <v-card outlined class="pa-1 my-1">
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-row dense">
                  <h5>Nr.</h5>
                  <h5 v-html="Lo_Question.nummer" class="font-weight-bold" />
                </div>
              </div>
            </v-card>
          </div>
          <!--End--Nummer-->
          <!--Rolle der Kandidatin-->
          <div>
            <v-card outlined class="pa-1 my-1">
              <div class="d-flex justify-space-between">
                <h5 class="font-weight-bold">
                  Rolle der Kandidatin / des Kandidaten:
                </h5>
              </div>
              <div class="d-flex justify-space-between">
                <div v-html="Lo_Question.rolleDesKandidat" />
              </div>
            </v-card>
          </div>
          <!--End--Rolle der Kandidatin-->
          <!--Ausgangssituation-->
          <div>
            <v-card outlined class="pa-1 my-1">
              <div class="d-flex justify-space-between">
                <h5 class="font-weight-bold">Ausgangssituation:</h5>
              </div>
              <div v-html="Lo_Question.ausgangssituation" />
            </v-card>
          </div>
          <!--End--Ausgangssituation-->
          <v-divider class="my-4" />
          <div class="my-4" />
          <!--Frage an den Kandidaten/an die Kandidatin:-->
          <div>
            <h5 class="font-weight-bold">
              Fragen an den Kandidaten / an die Kandidatin:
            </h5>
            <v-card
              outlined
              class="pa-1 my-1"
              v-for="(frage, index) in Lo_Question.fragen"
              :key="index"
            >
              <div class="d-flex justify-space-between">
                <h6>Frage: {{ index + 1 }}</h6>
              </div>

              <div v-html="frage.frage" />
              <div v-html="frage.ErwarteteAntworten" />
            </v-card>
          </div>
          <!--End--Frage an den Kandidaten/an die Kandidatin:-->
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "viewQuestion",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "View Question",
  },
  props: {
    id: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      La_Status: ["Definitiv", "QSK", "Entwurf", "In Übersetzung"],
      Lo_Question: {
        status: "In Übersetzung",
        nummer: 112211,
        rolleDesKandidat:
          "<p>Sie sind Spezialist/-in ASGS und arbeiten als SiBe in einer Bauunternehmung</p>",
        ausgangssituation:
          "<p>Bei einem Baustellenrundgang beobachten Sie, wie ein schwerer Bund Bewehrungseisen <br>(ca. 2 Tonnen schwer, ca. 5 m lang) mit dem Baustellenkran angehoben wird. Der Weg der <br>Bewehrungseisen am Kranhaken führt über eine öffentliche Strasse mit Gehweg und auf der Baustelle über verschiedene Arbeitsplätze hinweg.</p><p>Bei näherem Hinschauen stellen Sie fest, dass der Bund Bewehrungseisen nur an den dünnen Bindedrähten und nicht an einem ordentlichen Anschlagmittel (Ketten, Drahtseile, Gurten) <br>angehängt ist.</p>",
        fragen: [
          {
            frage:
              "<p>Beschreiben und beurteilen Sie das Gefahrenpotenzial.</p>",
            ErwarteteAntworten:
              "<p>1. Aufenthalt von Personen unter schwebenden Lasten ist zu vermeiden: <br>&nbsp;&nbsp; → Hohe potenzielle Energie → Lastabsturz ist grundsätzlich möglich.</p><p>- Betroffene können sein:<br>&nbsp;&nbsp;&nbsp;&nbsp; → Mitarbeitende des Betriebes, die nichts mit dem Materialtransport zu tun haben.<br>&nbsp;&nbsp;&nbsp;&nbsp; → Drittpersonen, Mitarbeitende anderer Betriebe.</p><p><strong>&nbsp;&nbsp;&nbsp;&nbsp; → </strong>Die Öffentlichkeit</p><p>2. Absturz der schweren Last: <br>- Dabei ist von einer akuten Gefährdung von Leib und Leben auszugehen.</p><p>- Bindedrähte als Anschlagmittel können versagen und zum Lastabsturz führen.</p>",
          },
          {
            frage:
              "<p><strong>Welches sind die rechtlichen Grundlagen?</strong><p>",
            ErwarteteAntworten:
              "<p>Grundsätzlich: UVG und VUV, BauAV, KranV (Kranverordnung)</p><p>Speziell: Suva-MB 88811 «Acht lebenswichtige Regeln für den Hochbau» <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Regel 3 «Lasten richtig anschlagen»</p><p>Suva Factsheet Nr. 33055 «Bewehrungseisen ...»</p>",
          },
        ],
      },
    };
  },
};
</script>
